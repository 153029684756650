import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { useAuth0 } from "@auth0/auth0-react";
import { connect } from 'react-redux';

import {
  setAuthInfo,
  setDealList,
  setLoading,
  setAccountInfo,
  setNotifyRule,
  setOrgMember,
  setSheetDataForm,
  setAuditSheetDataForm,
  setTaskLog,
  setTaskLogPage,
  setScreen,
  setDataInitStatus
} from './actions'
// import { RouteWithLayout } from './components';
// Main: 身份驗證後使用Layout，含有menu。 Minimal給未登入頁面使用，無menu
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  // Dashboard as DashboardView,
  // ProductList as ProductListView,
  // UserList as UserListView,
  // Typography as TypographyView,
  // Icons as IconsView,
  Account as AccountView,
  // Settings as SettingsView,
  // SignUp as SignUpView,
  SignIn as SignInView,
  UserManager as UserManagerView,
  NotFound as NotFoundView,
  TaskLog as TaskLogView,
  Pdf2html as Pdf2HtmlView,
  Audit as AuditView,
  AuditRows as AuditRowsView,
  AuditRowsForm as AuditRowsFormView,
  IntReport as IntReportView
} from './views';

import envConfig from './configuration/env.json';
import Loader from './image/Loading.gif';

import { DealList as DealListView, DealListDetail as DealListDetailView} from './views/DealList';
import { httpRequest, reqObj2From } from './lib';

// const NEED_VERIFY_MESSAGE = "Please verify your email before logging in.";

const MEDIUM_WIDTH = 950;
const MOBILE_WIDTH = 768;

const getScreenSize = () => {
  if (window.innerWidth < MOBILE_WIDTH) {
    return 'mobile';
  } else if (window.innerWidth < MEDIUM_WIDTH) {
    return 'medium';
  } else {
    return 'large';
  }
};

const AppRoutes = (props) => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  const initProfile = (token, callback) => {
    httpRequest('GET', '/api/account', {}, token, (statusCode, body) => {
      callback(body.status, body.message, body.info);
      if (!body.status) {
        return;
      }
      props.setAccountInfo({...body.info});
    });
  };

  const initCaseRule = (token, callback) => {
    httpRequest('GET', '/api/notifyrule', {}, token, (statusCode, response) => {
      const { status, message, notifyRule } = response; // { status, message, notifyRule }
      if (typeof callback === 'function') {
        callback(status, message, notifyRule);
      }
      if (!status) {
        return;
      }
      props.setNotifyRule(notifyRule);
    });
  }

  const initOrgMember = (token, callback) => {
    httpRequest('GET', '/api/manager/orgmember', {}, token, (statusCode, response) => {
      const { status, message, members } = response; // { status, message, members }
      if (typeof callback === 'function') {
        callback(status, message, members);
      }
      if (!status) {
        return;
      }
      props.setOrgMember(members);
    });
  }
  const initDealList = (token, callback) => {
    // console.log({ formInfos });
    httpRequest('GET', '/api/dealtrack', {}, token, (statusCode, response) => {
      const { status, message, data } = response; // { status, message, data }
      if (!status) {
        if (typeof callback === 'function') {
          callback(status, message, []);
        }
        return;
      }
      props.setDealList(data);
      callback(status, message, data);
    });
  };
  const initSheetDataForm = (token, callback) => {
    const sheetFormSrcs = ['sheet', 'audit'];
    let initCount = 0;
    let sumStatus = true;
    let sumMessage = '';

    httpRequest('GET', `/api/data_form/${sheetFormSrcs[0]}`, {}, token, (statusCode, response) => {
      initCount += 1;
      const { status, message, datas } = response; // { status, message, datas }
      sumStatus = sumStatus && status;
      sumMessage = `${sumMessage}${sheetFormSrcs[0]}: ${message}\n`;
      if (typeof callback === 'function' && initCount === sheetFormSrcs.length) {
        callback(sumStatus, sumMessage);
      }
      if (!status) {
        return;
      }
      props.setSheetDataForm(datas);
    });
    httpRequest('GET', `/api/data_form/${sheetFormSrcs[1]}`, {}, token, (statusCode, response) => {
      initCount += 1;
      const { status, message, datas } = response; // { status, message, datas }
      sumStatus = sumStatus && status;
      sumMessage = `${sumMessage}${sheetFormSrcs[1]}: ${message}\n`;
      if (typeof callback === 'function' && initCount === sheetFormSrcs.length) {
        callback(sumStatus, sumMessage);
      }
      if (!status) {
        return;
      }
      props.setAuditSheetDataForm(datas);
    });
  };

  const initTaskLog = (token, callback) => {
    const { page, perPage } = props.taskLogPage;
    const url = `/api/task_log?page=${page}&perPage=${perPage}`;
    httpRequest('GET', url, {}, token, (statusCode, body) => {
      if (typeof callback === 'function') {
        callback(body.status, body.message, body.taskLogs);
      }
      if (!body.status) {
        return;
      }
      if (body.taskLogs.length < perPage) {
        props.setTaskLogPage({ page, perPage, next: false });
      }
      props.setTaskLog(body.taskLogs);
    });
  };

  const handleWindowSizeChange = () => {
    props.setScreen(getScreenSize());
  };

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently({
        audience: envConfig.audience,
        scope: envConfig.scope
      }).then((accessToken) => {
        props.setAuthInfo({ ...user, accessToken });
        initProfile(accessToken, () => {
          // When a request is made at the same time, two new users will be created in the initialization process
          initSheetDataForm(accessToken, (isdfStatus, isdfMessage) => {
            if (!isdfStatus) {
              return;
            }
            props.setDataInitStatus({ sheetDataForm: true });
          });
        });
        // initCaseRule(accessToken);
        initOrgMember(accessToken);
        initTaskLog(accessToken);
      }).catch((err) => {
        console.log(err);
      });
    }
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, [isAuthenticated]);

  return (
    <>
      <Routes>
        <Route exact path="/" element={<MainLayout><DealListView/></MainLayout>} />
        <Route
          element={<MainLayout><DealListView/></MainLayout>}
          exact
          path="/pipeline"
        />
        <Route
          element={<MainLayout><DealListDetailView/></MainLayout>}
          path="/pipeline/:caseid"
        />
        <Route
          element={<MainLayout><AuditView/></MainLayout>}
          path="/audit"
        />
        <Route
          element={<MainLayout><AuditRowsView/></MainLayout>}
          path="/audit_datas"
        />
        <Route
          element={<MainLayout><AuditRowsFormView/></MainLayout>}
          path="/audit_datas/:id"
        />
        <Route
          element={<MainLayout><IntReportView/></MainLayout>}
          path="/int_report"
        />
        <Route
          element={<MainLayout><TaskLogView/></MainLayout>}
          exact
          path="/task_log"
        />
        <Route
          element={<MainLayout><AccountView/></MainLayout>}
          exact
          path="/account"
        />
        <Route
          element={<MainLayout><UserManagerView/></MainLayout>}
          exact
          path="/usermanager"
        />
        <Route
          element={<MainLayout><Pdf2HtmlView/></MainLayout>}
          exact
          path="/tool/pdf2html"
        />
        <Route
          element={<MinimalLayout><SignInView/></MinimalLayout>}
          exact
          path="/sign-in"
        />
        <Route
          element={<MinimalLayout><NotFoundView/></MinimalLayout>}
          exact
          path="/not-found"
        />
        {/* <Navigate to="/not-found" /> */}
      </Routes>

      {
        props.loading && (
          <div style={{ zIndex:'10', position:'fixed', top:'0', left: '0', backgroundColor:'#D3D3D3', opacity:'0.85', height:'100vh', width:'100vw', display:'flex', justifyContent:'center', alignItems:'center' }}>
            <div style={{  textAlign:'center'}}>
              <img src={Loader} style={{ width: '200px' }} alt='Loading...' />
              <h3 style={{color:'white'}}>Loading...</h3>
            </div>
          </div>
        )
      }
    </>
  );
};

const mapStateToProps = state => {
  const { taskLogPage, loading } = state;
  return { taskLogPage, loading };
};

export default connect(mapStateToProps, {
  setAuthInfo,
  setDealList,
  setLoading,
  setAccountInfo,
  setNotifyRule,
  setOrgMember,
  setSheetDataForm,
  setAuditSheetDataForm,
  setTaskLog,
  setTaskLogPage,
  setScreen,
  setDataInitStatus
})(AppRoutes);
