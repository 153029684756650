import React, { useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Divider, Drawer } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import BuildIcon from '@mui/icons-material/Build';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { Profile, SidebarNav } from './components';

import layoutCss from '../../../../assets/scss/layout.module.scss';

const Sidebar = props => {
  const { open, variant, onClose, className, account, ...rest } = props;
  const [pages, setPages] = React.useState([]);

  useEffect(() => {
    const myPages = [
      { title: 'Pipeline', href: '/pipeline', icon: <DashboardIcon /> },
      { title: 'Audit', href: '/audit', icon: <FactCheckIcon /> },
      { title: 'Audit Datas', href: '/audit_datas', icon: <CloudUploadIcon /> },
      { title: 'TaskLog', href: '/task_log', icon: <AssignmentTurnedInIcon /> },
      { title: 'Int Report', href: '/int_report', icon: <AssignmentTurnedInIcon /> },
      { title: 'Account', href: '/account', icon: <AccountBoxIcon /> }
    ];
    if (props.account.role === 'admin') {
      myPages.push({ title: 'User Manager', href: '/usermanager', icon: <AccountBoxIcon /> });
      myPages.push({ title: 'Pdf2html', href: '/tool/pdf2html', icon: <BuildIcon /> });
    }
    setPages(myPages);
  }, [props.account]);

  return (
    <Drawer
      anchor="left"
      classes={{ paper: layoutCss.MainSidebarSidebarDrawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        {...rest}
        className={clsx(layoutCss.MainSidebarSidebar, className)}
      >
        <Profile />
        <Divider className={layoutCss.MainSidebarSidebarDivider} />
        <SidebarNav
          className={layoutCss.MainSidebarSidebarNav}
          pages={pages}
        />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

const mapStateToProps = state => {
  const { account } = state;
  return { account };
};

export default connect(mapStateToProps, {})(Sidebar);
