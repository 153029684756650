import React, { forwardRef, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import RefreshIcon from '@mui/icons-material/Refresh';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { FormattedMessage, FormattedDate, injectIntl } from 'react-intl';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { connect } from 'react-redux';

import VisibilityIcon from '@mui/icons-material/Visibility';
import SearchIcon from '@mui/icons-material/Search';

import ExcelExportButton from '../../components/ExcelExportButton';
import { EnhancedTableHead, TableBodyRow } from '../../components/TableComponent';
import {
  setAccountInfo,
  setOrgMember,
  setDealList,
  setLoading,
} from '../../actions'; // redux action
import { generateQuarterTags } from './intReportUtil';
import { MutiPickSelect, InfiniteScrollMutiPickSelect } from '../../components/CustComponents/MutiPickSelect';

import { filterEngine } from '../../components/FilterComponent/filterLib';

import { httpRequest } from '../../lib';
import tableInfo from '../../configuration/intReportTableInfo.json';
import intFilterInfo from '../../configuration/intReportFilterInfo.json';
import viewCss from '../../assets/scss/view.module.scss';
import greedyFilterStr from 'reducer/greedyFilterStr';

const FILTER_DISPLAY = 'flatten'; // 'accordion' | 'flatten' | 'dialog'

const FILTER_FORM = {};
const TABLE_PER_PAGE = -1; // -1 disable page

const DEFAULT_RELOAD_DELAY = 2000; // 2 seconds

const TRANSACTION_TYPE_OPTIONS = [
  { label: "Existing Business", value: "Existing Business" },
  { label: "New Business", value: "New Business" }
];


const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const IntReport = (props) => {
  const timeoutRef = useRef(null);
  const [tableHeight, setTableHeight] = React.useState(70);

  const [filterQuarters, setFilterQuarters] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(TABLE_PER_PAGE);
  const [quarterOptions, setQuarterOptions] = React.useState([]);

  const [sheetFormInfos, setSheetFormInfos] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [temps, setTemps] = useState([]);

  const [rowsFilterInfo, setRowsFilterInfo] = useState({
    greedy: '',
    transactionType: [],
  });
  const [rows, setRows] = useState([]);

  const loadRows = (quarters, callback) => {
    const initQuartersStr = quarters.map((value) => `quarters=${value}`).join('&');
    const sheetSrc = `/api/int_sheet?${initQuartersStr}`
    httpRequest('GET', sheetSrc, null, props.auth.accessToken, (err, body) => {
      if (typeof callback === 'function') {
        callback(err, body);
        return;
      }
      if (body.status) {
        setRows(body.rows);
        props.setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (!props.auth.accessToken) {
      return;
    }
    // fetch data
    const mtOptions = [{ label: 'Unknown', value: '' }, ...generateQuarterTags(0, 24).map((tagStr) => {
      return { label: tagStr, value: tagStr };
    })];
    const initQuarters = [mtOptions[0].value, mtOptions[1].value];
    

    setQuarterOptions(mtOptions);
    setFilterQuarters(initQuarters);
    // TODO: get date. GET /api/int_sheet

    props.setLoading(true);
    httpRequest('GET', '/api/int_sheet/dataform', null, props.auth.accessToken, (err, body) => {
      if (body.status) {
        setSheetFormInfos(body.datas);

        loadRows(initQuarters, (lrErr, lrBody) => {
          setRows(lrBody.rows);
          props.setLoading(false);
        });
        return;
      }
      props.setLoading(false);
    });
    // get temp
    httpRequest('GET', '/api/export_temp', null, props.auth.accessToken, (err, body) => {
      if (body.status) {
        setTemps(body.datas);
        return;
      }

    });
  }, [props.auth.accessToken]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const displayHeaders =  tableInfo.headers.filter((tableHeader) => {
    return (tableHeader.role.length === 0) || tableHeader.role.includes(props.account.role);
  });

  const filteredRows = filterEngine(sheetFormInfos, rows, intFilterInfo, rowsFilterInfo);
  const displayRows = stableSort(filteredRows, getComparator(order, orderBy))

  const tableContainerOffset = (rowsPerPage > 0) ? tableHeight + 166 : tableHeight + 105;
  return (
    <>
      <div className={viewCss.IntReport}>
        <Paper className={viewCss.IntReportPaper}>
          <div style={{ margin: '0px 0px 25px 0px', height: `${tableHeight}px`}}>
            <h1 style={{ float: 'left', margin: '10px 20px' }}>
              <FormattedMessage id={'int_report'} />
            </h1>
            
            <div style={{ float: 'right' }}>
              <InfiniteScrollMutiPickSelect
                enable={true}
                style={{ minWidth: '150px', maxWidth: '250px' }}
                title={props.intl.formatMessage({ id: 'pick_quarters' })}
                value={filterQuarters}
                options={quarterOptions}
                onChange={(event) => {
                  setFilterQuarters(event.target.value);
                  // 清除先前的計時器（如果存在），以便在每次選擇時重新計時
                  if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current);
                  }

                  // 設置新的延遲計時器
                  timeoutRef.current = setTimeout(() => {
                    // 在選擇後 3 秒執行的動作
                    props.setLoading(true);
                    loadRows(event.target.value);
                    // 清除計時器
                    timeoutRef.current = null;
                  }, DEFAULT_RELOAD_DELAY);
                }}
                onScrollBottom={(event) => {
                  const mtOptions = generateQuarterTags(- quarterOptions.length, 12).map((tagStr) => {
                    return { label: tagStr, value: tagStr };
                  });
                  setQuarterOptions(quarterOptions.concat(mtOptions));
                }}
              />
              <MutiPickSelect
                enable={true}
                style={{ minWidth: '150px', maxWidth: '250px' }}
                title={props.intl.formatMessage({ id: 'pick_transaction_type' })}
                value={rowsFilterInfo.transactionType}
                options={TRANSACTION_TYPE_OPTIONS} // Array<{ label: string, value: any }>
                onChange={(event) => {
                  setRowsFilterInfo({ ...rowsFilterInfo, transactionType: event.target.value });
                }}
              />
              <TextField
                label={props.intl.formatMessage({ id: 'search' })}
                id={'field-search'}
                value={rowsFilterInfo.greedy}
                size="small"
                onChange={(event) => {
                  setRowsFilterInfo({ ...rowsFilterInfo, greedy: event.target.value });
                }}
              />
            </div>

            <ExcelExportButton btnStyle={{ float: 'right' }} exportTemps={temps} dealList={displayRows} />
            <Tooltip title={props.intl.formatMessage({ id: 'refresh' })}>
              <IconButton onClick={() => {
                props.setLoading(true);
                loadRows(filterQuarters);
              }} style={{ float: 'right' }}>
                <RefreshIcon fontSize='large' color='primary'/>
              </IconButton>
            </Tooltip>
          </div>

          <TableContainer>
            <Table
              stickyHeader
              className={viewCss.IntReportTable}
              aria-labelledby="tableTitle"
              size={'small'}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                onRequestSort={handleRequestSort}
                heads={displayHeaders}
                order={order}
                orderBy={orderBy}
              />
              <TableBodyRow
                rows={displayRows}
                headers={displayHeaders}
              />
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  const { auth, dealList, loading, account, notifyRule, orgMember } = state;
  return { auth, dealList, loading, account, notifyRule, orgMember };
};

export default connect(mapStateToProps, { setLoading })(withAuthenticationRequired(injectIntl(IntReport)));
