import React from 'react';
import { Grid2 } from '@mui/material';

import { AccountDetails } from './components';

import viewCss from '../../assets/scss/view.module.scss';

const Account = () => {

  return (
    <div className={viewCss.Account}>
      <Grid2 container spacing={4}>
        <Grid2 item size={12}>
          <AccountDetails />
        </Grid2>
      </Grid2>
    </div>
  );
};

export default Account;
