import * as XLSX from 'xlsx';
import { identName } from '../../lib';

const EXPORT_EXCLUDE_KEY = [
  { type: 'sys', key: 'case_uid'},
  { type: 'owner', key: 'uid'},
  { type: 'sys', key: 'organization'}
];
const DR_EXPORT_EXCLUDE_STATUS = ['Waiting', 'Open', 'Reject'];

const DEFAULT_CSS_STYLE = `<style type="text/css">
table {
  border-collapse: collapse;
  border:1px solid black;
}
th {
  border:1px solid black;
}
td {
  border:1px solid black;
}
</style>`;

const xlsxRendor_ws = (intl, exportAttrs, dealList) => {
  const colsNum = exportAttrs.length;
  const header = [...new Array(colsNum + 2)].map((a, index) => {
    return identName(index);
  });

  // attribute
  const attrJson = {};
  exportAttrs.forEach((attr, index) => {
    attrJson[header[index]] = intl.formatMessage({ id: attr.field_label_id, defaultMessage: attr.name });
  });
 
  // body
  const bodyArray = dealList.map((dealInfo) => {
    const json = {};
    exportAttrs.forEach((attr, index) => {
      // nosemgrep eslint.detect-object-injection -- exportAttrs is config value.
      const exportColumnValue = dealInfo[attr.field_key];
      let cellValue = Array.isArray(exportColumnValue) ? exportColumnValue.join(', ') : exportColumnValue;
      if (cellValue && attr.field_type === 'parser_user') {
        const strParts = cellValue.split('-');
        cellValue = [strParts[0], strParts[1]].join('-');
      }
      if (cellValue && attr.field_type === 'parser_product') {
        const strParts = cellValue.split('-');
        cellValue = strParts[2];
      }
      json[header[index]] = cellValue || attr.default_value;
    });
    return json;
  });

  const ws = XLSX.utils.json_to_sheet([attrJson].concat(bodyArray), { header, skipHeader: true });
  return ws;
};

const xlsxDownload_ws = (ws, fileName, sheetName) => {
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, ws, sheetName);
  // TODO: need to check detect-non-literal-fs-filename
  // nosemgrep eslint.detect-non-literal-fs-filename -- XLSX.writeFile is not fs
  XLSX.writeFile(workbook, fileName);
};

const ws_to_csv = (ws) => {
  return XLSX.utils.sheet_to_csv(ws);
};

const ws_to_txt = (ws) => {
  return XLSX.utils.sheet_to_txt(ws);
};

const ws_to_html = (ws) => {
  return XLSX.utils.sheet_to_html(ws);
};

const injectCSS = (html, cssStyle = DEFAULT_CSS_STYLE) => {
  return html.replace('</head>', `${cssStyle}</head>`);
};

export {
  xlsxRendor_ws,
  xlsxDownload_ws,
  ws_to_csv,
  ws_to_txt,
  ws_to_html,
  injectCSS
};
