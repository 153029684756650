import { CONSTANTS } from "./constants";

const getMonthFirstDate = (offset = 0, dateValue = new Date()) => {
  // 將輸入的日期轉換為 JavaScript Date 對象
  const inputDate = new Date(dateValue);
  // 獲取輸入日期的年份和月份
  let year = inputDate.getFullYear();
  let month = inputDate.getMonth();

  // 計算偏移後的月份和年份
  month += offset;
  year += Math.floor(month / 12);
  month = (month % 12 + 12) % 12;  // 確保月份為 0-11

  // 設定偏移後月份的第一天
  const firstDayOfOffsetMonth = new Date(year, month, 1);

  return firstDayOfOffsetMonth;
};

const INIT_FILTER_INFO = {
  originalReceiptDateLeft: getMonthFirstDate(-3),
};

export default (state = INIT_FILTER_INFO, action) => {
  switch (action.type) {
    case CONSTANTS.SET_FILTER_INFO :
      return action.value;
    default:
      return state;
  }
}
