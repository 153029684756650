import React, { forwardRef, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
import { injectIntl, FormattedDate, FormattedMessage } from 'react-intl';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { confirmAlert } from 'react-confirm-alert';
import { connect } from 'react-redux';
// material ui
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TextField from '@mui/material/TextField';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Badge from '@mui/material/Badge';
import AttachFileIcon from '@mui/icons-material/AttachFile';
// material ui icons
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EmailIcon from '@mui/icons-material/Email';
// cust
import { EnhancedTableHead, TableBar } from '../../components/TableComponent';
import AttachmentDialog from './AttachmentDialog';
// import EmailDialog from './emailDialog';
// import { EmailDialog } from '../../components/EmailComponent';
import RejectFormDialog from './RejectFormDialog';
import {
  setAccountInfo,
  setOrgMember,
  setDealList,
  setLoading,
  setFilterInfo,
  setOrder,
  setOrderBy,
  setDataInitStatus
} from '../../actions'; // redux action
import { filterEngine } from '../../components/FilterComponent/filterLib';
import { checkEngine } from '../../common/ruleEngine';
import Loader from '../../image/Loading.gif';
import { httpRequest, reqObj2From, form2ReqObj, genShortName } from '../../lib';
import { renderHeader, getComparator, stableSort } from './dealUtil';

import tableInfo from '../../configuration/accountingTableInfo.json';
import tableFilterInfo from '../../configuration/accountingTableFilterInfo.json';

import viewCss from '../../assets/scss/view.module.scss';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './deallist.css';
import "date-fns";

const DISPLAY_SEND_MAIL_ROLES = ['manager', 'admin'];

const ACCOUNTING_POSTED_KEY = 'accounting_posted';
const DECLINED_STR = 'declined';

const ITEM_HEIGHT = 48;
const TABLE_PER_PAGE = -1; // -1 disable page

const HANDLED_CONFIRMED_STATUS = ['confirmed'];
const REVIEW_CONFIRMED_STATUS = ['reviewed', 'partial_reviewed'];
const ACC_CONFIRMED_STATUS = ['posted', 'partial_posted'];

const ADDITIONAL_RECEIVER = ['jason'];
const ADDITIONAL_CC_RECEIVER = ['trisanna'];

// 是否等待處理完畢，或使用緩存方式送入請求當作完成
// const NEW_CASE_TASK_LOG_TOGGLE = false;
const UPDATE_CASE_TASK_LOG_TOGGLE = true;

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const DealList = (props) => {
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(TABLE_PER_PAGE);
  const [openRejectDialog, setOpenRejectDialog] = React.useState(false);
  const [chooseForm, setChooseForm] = React.useState({});
  const [rowIndex, setRowIndex] = React.useState(-1);
  const [editColumnIndex, setEditColumnIndex] = React.useState(-1);
  const [editColumnValue, setEditColumnValue] = React.useState(null);
  const [snackMsg, setSnackMsg] = React.useState('');
  const [attachmentRow, setAttachmentRow] = React.useState(null);
  const [moreMenuAnchorEl, setMoreMenuAnchorEl] = React.useState(null);
  const [moreMenuIndex, setMoreMenuIndex] = React.useState(-1);
  const [tableHeight, setTableHeight] = React.useState(200);
  const [rejectProgress, setRejectProgress] =  React.useState(false);

  const initDealList = (token, callback) => {
    httpRequest('GET', '/api/dealtrack', {}, token, (statusCode, response) => {
      const { status, message, data } = response; // { status, message, data }
      if (!status) {
        if (typeof callback === 'function') {
          callback(status, message, []);
        }
        return;
      }
      props.setDealList(data);
      callback(status, message, data);
    });
  };

  useEffect(() => {
    // Scroll to top
    window.scrollTo(0, 0);
    if (props.dataInitStatus.sheetDataForm && !props.dataInitStatus.dealList) {
      props.setLoading(true);
      initDealList(props.auth.accessToken, () => {
        props.setLoading(false);
        props.setDataInitStatus({ dealList: true });
      });
    }
  }, [props.dataInitStatus.sheetDataForm]);

  const handleRequestSort = (event, property) => {
    const isAsc = props.orderBy === property && props.order === 'asc';
    props.setOrder(isAsc ? 'desc' : 'asc');
    props.setOrderBy(property);
    setRowIndex(-1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const initProfile = (token, callback) => {
    httpRequest('GET', '/api/account', {}, token, (statusCode, body) => {
      if (!body.status) {
        callback();
        return;
      }
      props.setAccountInfo({...body.info});
      callback();
    });
  };

  const initOrgMember = (token) => {
    httpRequest('GET', '/api/manager/orgmember', {}, token, (statusCode, response) => {
      const { status, message, members } = response;
      if (!status) {
        // TODO: alert message box
        return;
      }
      props.setOrgMember(members);
    });
  }

  const loadDealList = (token, callback) => {
    httpRequest('GET', '/api/dealtrack', {}, token, (statusCode, response) => {
      const { status, message, data } = response;
      if (!status) {
        // TODO: alert message box
        callback();
        return;
      }
      props.setDealList(data);
      callback();
    });
  };

  const onRefreshDeal = (event) => {
    props.setLoading(true);

    initOrgMember(props.auth.accessToken);
    initProfile(props.auth.accessToken, () => {
      // When a request is made at the same time, two new users will be created in the initialization process
      loadDealList(props.auth.accessToken, () => {
        props.setLoading(false);
        // props.setDealList(formatData);
      });
    });
  }

  const onFilterInfoChange = (key, value) => {
    const json = JSON.parse(JSON.stringify(props.filterInfo));
    json[key] = value;
    setRowIndex(-1);
    setPage(0);
    props.setFilterInfo(json);
  };

  const onRowClick = (index) => (event) => {
    if (rowIndex !== index) {
      setEditColumnValue(null);
      setEditColumnIndex(-1);
    }
    setRowIndex(index);
  };

  const onMoreMenuClick = (menuIndex) => (event) => {
    setMoreMenuIndex(menuIndex);
    setMoreMenuAnchorEl(event.currentTarget);
  };
  const onMoreMenuClose = () => {
    setMoreMenuIndex(-1);
    setMoreMenuAnchorEl(null);
  };

  const onAttachmentOpen = (row) => (event) => {
    // show row attachment list dialog
    setAttachmentRow(row);
    // onMoreMenuClose();
  };

  const onAttachmentDialogClose = () => {
    setAttachmentRow(null);
  };

  const onUploadSubmit = (file, callback) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileInfo', JSON.stringify({
      name: file.name,
      mimetype: file.type,
      dataForm: attachmentRow.dataForm,
    }));
    const options ={
      method: 'PUT',
      url: `/api/attachment/${attachmentRow.dataForm}/${attachmentRow.id}`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${props.auth.accessToken}`
      }
    };
    setLoading(true);
    axios(options).then((res) => {
      setLoading(false);
      callback();
    }).catch((err) => {
      setLoading(false);
      callback();
    });
  };

  const onAccountingPosted = (row, accounting_posted) => (event) => {
    const method = 'POST';
    const sheetDataFormConfig = props.sheetDataForm.find((fi) => {
      return fi.name === row.dataForm;
    });

    const { sheet_id_key: idKey } = sheetDataFormConfig;
    const url = `/api/dealtrack/posted/${row.dataForm}/${row[idKey]}`;
    const json = {
      accounting_posted: accounting_posted ? 'posted' : 'unposted'
    };

    props.setLoading(true);
    onMoreMenuClose();
    httpRequest(method, url, json, props.auth.accessToken, (statusCode, body) => {
      props.setLoading(false);
      if (body.status) {
        const dealListIndex = props.dealList.findIndex((dl) => {
          return dl[idKey] === row[idKey]
        });
        const json = JSON.parse(JSON.stringify(props.dealList));
        json[dealListIndex].accounting_posted = accounting_posted ? 'posted' : 'unposted';
        json[dealListIndex].accounting_posted_by = accounting_posted ? props.account.name : '';
        json[dealListIndex].accounting_posted_time = accounting_posted ? new Date() : '';
        props.setDealList(json);
      }
      confirmAlert({
        title: body.status ? 'Success' : 'Warning',
        message: body.status ? 'Success' : body.message,
        buttons: [{ label: 'Yes' }],
        closeOnClickOutside: false
      });
    });
  };

  const onSubmitChange = (row, showMessage = true) => {
    if (UPDATE_CASE_TASK_LOG_TOGGLE) {
      setLoading(true);
    }
    const options = {
      method: 'POST',
      url: `/api/dealtrack/${row.dataForm}/${row.id}`,
      data: { data: row },
      headers: {
        Authorization: `Bearer ${props.auth.accessToken}`
      }
    };
    axios(options).then((res) => {
      // show update res
      if (UPDATE_CASE_TASK_LOG_TOGGLE) {
        if (showMessage) {
          setSnackMsg(res.data.message);
        }
      } else {
        setLoading(false);
        if (showMessage) {
          confirmAlert({
            title: res.data.status ? 'Success' : 'Warning',
            message: res.data.status ? 'Success' : res.data.message,
            buttons: [{ label: 'Yes' }],
            closeOnClickOutside: false
          });
        }
      }
    }).catch((err) => {
      // show update res
      if (UPDATE_CASE_TASK_LOG_TOGGLE) {
        setSnackMsg(err.message);
      } else {
        setLoading(false);
        confirmAlert({
          title: 'Warning',
          message: err.message,
          buttons: [{ label: 'Yes' }],
          closeOnClickOutside: false
        });
      }
    })
  };

  const onPostedSelectChange = (row, key, targetValue) => {
    const targetIndex = props.dealList.findIndex((dl) => {
      return dl.id === row.id;
    });
    if (targetIndex > -1) {
      const sourceRows = JSON.parse(JSON.stringify(props.dealList));
      const targetRow = JSON.parse(JSON.stringify(row));
      targetRow[key] = targetValue;
      if (ACC_CONFIRMED_STATUS.includes(targetValue)) {
        targetRow.accounting_posted_by = props.account.name;
        targetRow.accounting_posted_time = new Date();
      } else if (targetValue === DECLINED_STR) {
        targetRow.review_status = 'not_reviewed';
        targetRow.reviewer = '';
        targetRow.reviewed_date = '';
      } else {
        targetRow.accounting_posted_by = '';
        targetRow.accounting_posted_time = '';
      }
      sourceRows[targetIndex] = targetRow;
      props.setDealList(sourceRows);
      // todo: add update req
      onSubmitChange(sourceRows[targetIndex]);
    }
  };

  const onPostedSelectChangeCheck = (row, key) => (event) => {
    if (key === ACCOUNTING_POSTED_KEY && event.target.value === DECLINED_STR) {
      // showMessage and check
      setOpenRejectDialog(true);
      setChooseForm(row);
    } else {
      onPostedSelectChange(row, key, event.target.value);
    }
  };

  const onReviewSelectChange = (row, key) => (event) => {
    const targetIndex = props.dealList.findIndex((dl) => {
      return dl.id === row.id;
    });
    if (targetIndex > -1) {
      const sourceRows = JSON.parse(JSON.stringify(props.dealList));
      const targetRow = JSON.parse(JSON.stringify(row));
      targetRow[key] = event.target.value;
      if (REVIEW_CONFIRMED_STATUS.includes(event.target.value)) {
        targetRow.reviewer = props.account.name;
        targetRow.reviewed_date = new Date();
      } else {
        targetRow.reviewer = '';
        targetRow.reviewed_date = '';
      }
      sourceRows[targetIndex] = targetRow;
      props.setDealList(sourceRows);
      // todo: add update req
      onSubmitChange(sourceRows[targetIndex]);
    }
  };

  const onLoadFiles = (filesInfo) => {
    if (filesInfo.length !== attachmentRow.attachment) {
      const targetIndex = props.dealList.findIndex((dl) => {
        return dl.id === attachmentRow.id;
      });
      // update count.
      if (targetIndex > -1) {
        const sourceRows = JSON.parse(JSON.stringify(props.dealList));
        const targetRow = JSON.parse(JSON.stringify(attachmentRow));
        targetRow.attachment = filesInfo.length;
        sourceRows[targetIndex] = targetRow;
        props.setDealList(sourceRows);
        onSubmitChange(targetRow, false);
      }
    }
  };

  const regenerateRow = (targetRow) => {
    const { module_id, module_type } = targetRow;
    const url = `/api/webhook/zoho/books/${module_type}/${module_id}`;
    const options = {
      method: 'POST',
      url,
      data: { dataForm: targetRow.dataForm },
      headers: {
        Authorization: `Bearer ${props.auth.accessToken}`
      }
    };
    const responseCallback = (resMsg) => {
      confirmAlert({
        title: 'Message',
        message: resMsg,
        buttons: [{ label: props.intl.formatMessage({ id: 'confirm' })}],
        closeOnClickOutside: false
      });
    }
    setLoading(true);
    axios(options).then((res) => {
      setLoading(false);
      responseCallback(res.data.message);
    }).catch((err) => {
      setLoading(false);
      responseCallback(err.message);
    });
  }

  const onRegenerate = (targetRow) => (event) => {
    onMoreMenuClose();
    confirmAlert({
      title: 'Message',
      message: props.intl.formatMessage({ id: 'regenerate_msg' }),
      buttons: [
        { label: 'Yes', onClick: () => { regenerateRow(targetRow); } },
        { label: 'No' },
      ],
      closeOnClickOutside: false
    });
  };

  const onRejectSubmit = (form) => {
    const row = JSON.parse(JSON.stringify(chooseForm));
    onPostedSelectChange(row, ACCOUNTING_POSTED_KEY, DECLINED_STR);

    const content = props.intl.formatMessage({
      id: 'reject_comment_text'
    }, {
      content: form.content,
      module_id: row.module_id,
      module_type: row.module_type,
      accounting_posted_by: row.accounting_posted_by,
      handled_confirmed_by: row.handled_confirmed_by,
      original_receipt_number: row.original_receipt_number,
      uniform_invoice: row.uniform_invoice,
      summary: row.summary,
      project_name: row.project_name,
      special_note: row.special_note,
      amount_before_tax: row.amount_before_tax,
      tax: row.tax,
      currency: row.currency,
      amount: row.amount
    });

    // mail in here.
    const emilObj = {
      receiver: [row.handled_confirmed_by, ...ADDITIONAL_RECEIVER],
      ccReceiver: [...ADDITIONAL_CC_RECEIVER],
      subject: props.intl.formatMessage({ id: 'reject_subject_title' }),
      content
    };

    const src = '/api/email/nickname';
    setRejectProgress(true);
    httpRequest('POST', src, emilObj, props.auth.accessToken, (statusCode, body) => {
      setChooseForm({});
      setOpenRejectDialog(false);
      setRejectProgress(false);
      setSnackMsg(body.message);
    });
  };

  const onFilterWHChange = (width, height) => {
    setTableHeight(height);
  };
  const filteredRows = filterEngine(props.sheetDataForm, props.dealList, tableFilterInfo, props.filterInfo);
  const displayedRows = stableSort(filteredRows, getComparator(props.order, props.orderBy));
  const displayRows = (rowsPerPage > 0) ? displayedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : displayedRows;

  const displayHeaders = renderHeader(props.account.role, props.screen, tableInfo);
  // const emptyRows = rowsPerPage - Math.min(rowsPerPage, props.dealList.length - page * rowsPerPage);
  const tableContainerOffset = (rowsPerPage > 0) ? tableHeight + 166 : tableHeight + 105;
  return (
    <div className={viewCss.DealList}>
      <Paper className={viewCss.DealListPaper}>
        <div style={{ margin: '0px 0px 25px 0px', height: `${tableHeight}px`}}>
          <TableBar
            title={props.intl.formatMessage({ id: 'pipeline' })}
            screen={props.screen}
            onAddClick={(event) => {
              navigate('/pipeline/new');
            }}
            onRefreshClick={onRefreshDeal}
            onFilterFormChange={onFilterInfoChange}
            filterInfos={tableFilterInfo}
            filterForm={props.filterInfo}
            onFilterWHChange={onFilterWHChange}
          />
        </div>
        <TableContainer style={{ height: `calc(100vh - ${tableContainerOffset}px)` }}>
          <Table
            stickyHeader
            className={viewCss.DealListTable}
            aria-labelledby="tableTitle"
            size={'small'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              onRequestSort={handleRequestSort}
              heads={displayHeaders}
              order={props.order}
              orderBy={props.orderBy}
            />
            <TableBody>
              {
                displayRows.map((row, index) => {
                  const isWarn = props.notifyRule.some((myRule) => {
                    return checkEngine(myRule, row);
                  });
                  // TODO: update warn condition.
                  // const rowClassName = isWarn ? viewCss.DealListWarnRow :
                  //   (row.case_status === 'Waiting') ? viewCss.DealListShineRow : viewCss.DealListCalmRow;
                  const rowClassName = (rowIndex === index) ? viewCss.DealListCalmRowSelected : viewCss.DealListCalmRow;
                  const dataFormIndex = props.sheetDataForm.findIndex((formInfo) => {
                    return formInfo.name === row.dataForm;
                  });
                  const { sheet_id_key: sheetIdKey } = props.sheetDataForm[dataFormIndex];
                  return (
                    <TableRow hover role='checkbox' tabIndex={-1} key={`${index}`} className={rowClassName} onClick={onRowClick(index)}>
                      {
                        displayHeaders.map((headCell, headCellIndex) => {
                          if (headCell.type === 'more_menu') {
                            return (
                              <>
                                <TableCell padding="checkbox" key={`table_cell_${index}_${headCellIndex}`}>
                                  <IconButton aria-label="view" onClick={onMoreMenuClick(index)}>
                                    <MoreHorizIcon/>
                                  </IconButton>
                                </TableCell>
                                <Menu
                                  id="long-menu"
                                  MenuListProps={{ 'aria-labelledby': 'long-button' }}
                                  anchorEl={moreMenuAnchorEl}
                                  open={index === moreMenuIndex && Boolean(moreMenuAnchorEl)}
                                  onClose={onMoreMenuClose}
                                  PaperProps={{
                                    style: { maxHeight: ITEM_HEIGHT * 4.5, width: '20ch' }
                                  }}
                                >
                                  <MenuItem component={CustomRouterLink} to={`/pipeline/${row[sheetIdKey]}`}>
                                    <FormattedMessage id={'edit'} />
                                  </MenuItem>
                                  <MenuItem onClick={onRegenerate(row)}>
                                    <FormattedMessage id={'regenerate'} />
                                  </MenuItem>
                                </Menu>
                              </>
                            );
                          }
                          if (headCell.type === 'edit') {
                            return (
                              <TableCell padding="checkbox" key={`table_cell_${index}_${headCellIndex}`}>
                                <IconButton
                                  aria-label="view"
                                  component={CustomRouterLink}
                                  to={`/pipeline/${row[sheetIdKey]}`}
                                >
                                  <VisibilityIcon style={{ color:'#dadada' }}/>
                                </IconButton>
                              </TableCell>
                            );
                          }
                          if (headCell.type === 'boolean') {
                            const TRUE_VALUES = [true, 'TRUE', 1];
                            return TRUE_VALUES.includes(row[headCell.id]) ? (
                              <TableCell align="left" key={`table_cell_${index}_${headCellIndex}`}>
                                <CheckCircleIcon style={{ fontSize: '16px' }}/>
                              </TableCell>
                            ) : (
                              <TableCell align="left" key={`table_cell_${index}_${headCellIndex}`}/>
                            );
                          }
                          if (headCell.type === 'select') {
                            const lkItem = headCell.lookup.find((lkObj) => {
                              return row[headCell.id] === lkObj.value;
                            });
                            if (!lkItem) {
                              return (
                                <TableCell align="left" key={`table_cell_${index}_${headCellIndex}`}/>
                              );
                            }
                            return (
                              <TableCell align="left" key={`table_cell_${index}_${headCellIndex}`}>
                                <FormattedMessage id={lkItem.label_id} defaultMessage={lkItem.label}/>
                              </TableCell>
                            );
                          }
                          // ignore empty value
                          if (headCell.type === 'date' && row[headCell.id]) {
                            return (
                              <TableCell align="left" key={`table_cell_${index}_${headCellIndex}`}>
                                <FormattedDate value={row[headCell.id]} />
                              </TableCell>
                            );
                          }
                          if (headCell.type === 'posted_select') {
                            if (rowIndex !== index) {
                              const lkItem = headCell.lookup.find((lkObj) => {
                                return row[headCell.id] === lkObj.value;
                              });
                              return (
                                <TableCell align="left" padding="none" key={`table_cell_${index}_${headCellIndex}`}>
                                  {lkItem ? <FormattedMessage id={lkItem.label_id} defaultMessage={lkItem.label} /> : null}
                                </TableCell>
                              );
                            }
                            // const handleCheck = HANDLED_CONFIRMED_STATUS.includes(row.handled_confirmed );
                            const reviewCheck = REVIEW_CONFIRMED_STATUS.includes(row.review_status );
                            // const enableEdit = handleCheck && reviewCheck;
                            const enableEdit = reviewCheck;
                            // select trigger btn
                            return (
                              <TableCell align="left" padding="none" key={`table_cell_${index}_${headCellIndex}`} style={{ padding: '0px 10px'}}>
                                <Select
                                  style={{ minWidth: '68px', width: '100%' }}
                                  label={props.intl.formatMessage({ id: headCell.label_id })}
                                  displayEmpty
                                  value={row[headCell.id]}
                                  onChange={onPostedSelectChangeCheck(row, headCell.id)}
                                  disabled={!enableEdit}
                                  IconComponent={null}
                                >
                                  {
                                    headCell.lookup.map((item, itemIndex) => {
                                      return (
                                        <MenuItem
                                          key={`${item.value || item.label}-${itemIndex}`}
                                          value={item.value}
                                        >
                                          <FormattedMessage id={item.label_id} defaultMessage={item.label} />
                                        </MenuItem>
                                      );
                                    })
                                  }
                                </Select>
                              </TableCell>
                            );
                          }
                          if (headCell.type === 'reviewed_select') {
                            // select trigger btn
                            if (rowIndex !== index) {
                              const lkItem = headCell.lookup.find((lkObj) => {
                                return row[headCell.id] === lkObj.value;
                              });
                              return (
                                <TableCell align="left" padding="none" key={`table_cell_${index}_${headCellIndex}`}>
                                  {lkItem ? <FormattedMessage id={lkItem.label_id} defaultMessage={lkItem.label} /> : null}
                                </TableCell>
                              );
                            }

                            return (
                              <TableCell align="left" padding="none" key={`table_cell_${index}_${headCellIndex}`} style={{ padding: '0px 10px'}}>
                                <Select
                                  style={{ width: '100%' }}
                                  label={props.intl.formatMessage({ id: headCell.label_id })}
                                  displayEmpty
                                  value={row[headCell.id]}
                                  onChange={onReviewSelectChange(row, headCell.id)}
                                  IconComponent={null}
                                >
                                  {
                                    headCell.lookup.map((item, itemIndex) => {
                                      return (
                                        <MenuItem
                                          key={`${item.value || item.label}-${itemIndex}`}
                                          value={item.value}
                                        >
                                          <FormattedMessage id={item.label_id} defaultMessage={item.label} />
                                        </MenuItem>
                                      );
                                    })
                                  }
                                </Select>
                              </TableCell>
                            );
                          }
                          if (headCell.type === 'attachment') {
                            if (rowIndex !== index) {
                              return (
                                <TableCell align="left" padding="none" key={`table_cell_${index}_${headCellIndex}`}>
                                  {row[headCell.id]}
                                </TableCell>
                              );
                            }
                            return (
                              <TableCell align="left" padding="none" key={`table_cell_${index}_${headCellIndex}`}>
                                <IconButton aria-label={`accachment_${headCellIndex}`} onClick={onAttachmentOpen(row)}>
                                  <Badge badgeContent={row[headCell.id]}>
                                    <AttachFileIcon />
                                  </Badge>
                                </IconButton>
                              </TableCell>
                            );
                          }
                          if (headCell.type === 'short_name') {
                            return (
                              <TableCell align="left" padding="none" key={`table_cell_${index}_${headCellIndex}`}>
                                {genShortName(row[headCell.id])}
                              </TableCell>
                            );
                          }
                          return (
                            <TableCell align="left" padding="none" key={`table_cell_${index}_${headCellIndex}`}>
                              {row[headCell.id]}
                            </TableCell>
                          );
                        })
                      }
                    </TableRow>
                  );
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
        {
          rowsPerPage > 0 &&
          <TablePagination
            rowsPerPageOptions={[TABLE_PER_PAGE]}
            component="div"
            count={displayedRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
      </Paper>
      {/* Snack message bar */}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={!!snackMsg}
        autoHideDuration={3000}
        onClose={() => {
          setSnackMsg('');
        }}
        message={snackMsg}
      />
      {/* Send Reject Reason Dialog */}
      <RejectFormDialog
        open={openRejectDialog}
        progress={rejectProgress}
        onSubmit={onRejectSubmit}
        onClose={(event, reason) => {
          if (reason && reason === 'backdropClick') {
            return;
          }
          setOpenRejectDialog(false);
        }}
      />
      <AttachmentDialog
        open={Boolean(attachmentRow)}
        title={attachmentRow ? attachmentRow.documentNumber : ''}
        saveText={props.intl.formatMessage({ id: 'close' })}
        handleSave={onAttachmentDialogClose}
        formData={attachmentRow}
        onUploadSubmit={onUploadSubmit}
        onLoadFiles={onLoadFiles}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  const {
    auth,
    dealList,
    loading,
    notifyRule,
    // filterStr,
    account,
    orgMember,
    // statusFilter,
    // updateRangeFilter,
    // productTypeFilter,
    // progressFilter,
    // estimatedClosingFilter,
    order,
    orderBy,
    // filterOwner,
    // greedyFilterStr,
    sheetDataForm,
    // filterDataForm,
    // formInfos,
    filterInfo,
    screen,
    dataInitStatus
  } = state;
  return {
    auth, dealList, loading, notifyRule, account, orgMember, order, orderBy, sheetDataForm, filterInfo, screen, dataInitStatus
  };
};

export default connect(mapStateToProps, {
  setAccountInfo,
  setOrgMember,
  // setStatusFilter,
  setDealList,
  setLoading,
  // setFilterStr,
  // setUpdateRangeFilter,
  // setProductTypeFilter,
  // setProgressFilter,
  // setEstimatedClosingFilter,
  setOrder,
  setOrderBy,
  // setFilterOwner,
  // setGreedyFilterStr,
  // setFilterDataForm,
  setFilterInfo,
  setDataInitStatus
})(withAuthenticationRequired(injectIntl(DealList)));
