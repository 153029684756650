import React, { forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { withAuthenticationRequired } from '@auth0/auth0-react';
// material-ui component
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import 'react-confirm-alert/src/react-confirm-alert.css';
// other component
import { confirmAlert } from 'react-confirm-alert';
// cust component
import {
  FieldLayout,
  FormControlFieldLayout,
  RefreshButtonLayout
} from '../../components/CustComponents/CustLayout';
import FormViewer from '../../components/CustComponents/FormViewer';
import { DatePicker } from '../../components/CustComponents/DatePicker';
// cust util
import { httpRequest, reqObj2From, form2ReqObj, mapsConst } from '../../lib';
import { setAuditDatas, setLoading, setDataInitStatus } from '../../actions';
import { duplicateCheck, dataValidation } from './auditUtil';
// data and css
import validateRules from '../../configuration/dealListValidation.json';
import viewCss from '../../assets/scss/view.module.scss';

const INIT_FORM = {};

const AUDIT_ROWS_DATA_FORM_INDEX = 0;

const renderFormViewerfieldsInfo = (attrNames, attrInfos, formValue) => {
  // 先filter再sort，避免出現attrNames存在但attrInfos不存在情況。
  const targetAttrs = attrInfos.filter((aInfo) => {
    return attrNames.includes(aInfo.field_key);
  });
  const viewerFieldsInfo = attrNames.map((name) => {
    const attr = targetAttrs.find(item => item.field_key === name);
    // edit_stage_field
    const editable = (attr.edit_stage_field && attr.edit_stages.length > 0)
      ? attr.edit_stages.includes(formValue[attr.edit_stage_field]) : true; // (isNew || name !== 'data_form');
    return {
      editable,
      required: attr.required,
      descriptionId: '',
      description: attr.description,
      fieldLabelId: attr.field_label_id,
      fieldKey: attr.field_key,
      fieldType: attr.field_type,
      lookup: attr.lookup
    };
  });
  return viewerFieldsInfo;
};

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ float: 'left', marginRight:'10px' }}>
    <RouterLink {...props} />
  </div>
));

// *******************************************************************************************
// Main Component
// *******************************************************************************************
const AuditRowsForm = (props) => {
  const navigate = useNavigate();
  const [rowIndex, setRowIndex] = React.useState(-1);
  const [isNew, setIsNew] = React.useState(false);
  const [isFormEdited, setIsFormEdited] = React.useState(false); // check form is edited
  const [openEmailDialog, setOpenEmailDialog] = React.useState(false); // show email dialog
  const [formValue, setFormValue] = React.useState({});

  const initForm = (auditDatas) => {
    const { pathname } = window.location;
    const formId = pathname.split('/').slice(-1)[0];
    if (formId === 'new') {
      const newForm = JSON.parse(JSON.stringify(INIT_FORM));
      setIsNew(true);
      setIsFormEdited(false);
      setFormValue(newForm);
      return;
    }
    const { sheet_id_key: sheetIdKey } = props.auditSheetDataForm[AUDIT_ROWS_DATA_FORM_INDEX] || {};
    const index = auditDatas.findIndex((aData) => {
      return aData[sheetIdKey] === formId;
    });
    setRowIndex(index); // deal current index
    setIsFormEdited(false);
    setFormValue({ ...auditDatas[index] });
  };

  const initAuditRows = (accessToken, callback) => {
    httpRequest('get', '/api/audit/rows', {}, accessToken, (err, body) => {
      const { status, message, datas } = body; // { status, message, datas }
      if (typeof callback === 'function') {
        callback(status, message, datas);
      }
      if (!status) {
        return;
      }
    });
  };
/*
  useEffect(() => {
    window.scrollTo(0, 0);
    const { pathname } = window.location;
    const formId = pathname.split('/').slice(-1)[0];
    if (formId === 'new') {
      const newForm = JSON.parse(JSON.stringify(INIT_FORM));
      setIsNew(true);
      setIsFormEdited(false);
      setFormValue(newForm);
      return;
    }
    const { sheet_id_key: sheetIdKey } = props.auditSheetDataForm[AUDIT_ROWS_DATA_FORM_INDEX] || {};
    const index = props.auditDatas.findIndex((aData) => {
      return aData[sheetIdKey] === formId;
    });
    setRowIndex(index); // deal current index
    setIsFormEdited(false);
    setFormValue({ ...props.auditDatas[index] });
  }, []); // let it run one time when mount.
*/
useEffect(() => {
  window.scrollTo(0, 0);
  if (props.dataInitStatus.sheetDataForm && props.auditSheetDataForm.length > 0) {
    if (!props.dataInitStatus.auditRows) {
      props.setLoading(true);
      initAuditRows(props.auth.accessToken, (iStatus, iMessage, iDatas) => {
        props.setAuditDatas(iDatas);
        props.setLoading(false);
        props.setDataInitStatus({ auditRows: true });
        initForm(iDatas);
      });
    } else {
      initForm(props.auditDatas);
    }
  }
}, [props.dataInitStatus.sheetDataForm, props.auditSheetDataForm.length]);

  const onSubmitForm = async (event) => {
    const vaildateInfo = dataValidation(isNew, formValue, props.auditSheetDataForm[AUDIT_ROWS_DATA_FORM_INDEX].attributes);

    if (!vaildateInfo.status) {
      confirmAlert({
        title: 'Warning',
        message: props.intl.formatMessage({ id: vaildateInfo.messageId }),
        closeOnClickOutside: false,
        buttons: [{ label: 'Confirm' }]
      });
      return;
    }
    if (isNew) {
      const checkResult = duplicateCheck(props.auditDatas, [formValue]);
      if (checkResult.duplicateJsons.length > 0) {
        confirmAlert({
          title: 'Warning',
          message: props.intl.formatMessage({ id: 'duplicate_invoice_num' }),
          closeOnClickOutside: false,
          buttons: [{ label: 'Confirm' }]
        });
        return;
      }
    }

    props.setLoading(true);
    const { sheet_id_key: idKey } = props.auditSheetDataForm[AUDIT_ROWS_DATA_FORM_INDEX];
    const method = isNew ? 'PUT' : 'POST';
    const endpoint = isNew ? `/api/audit/rows` : `/api/audit/row/${formValue[idKey]}`;

    const reqBody = isNew ? { datas: [formValue] } : { data: formValue };

    let dialogMsgBody = { status: true, message: 'Success' };

    dialogMsgBody = await new Promise((resolve, reject) => {
      httpRequest(method, endpoint, reqBody, props.auth.accessToken, (statusCode, body) => {
        resolve(body);
      });
    });

    if (!dialogMsgBody.status) {
      // show error
      confirmAlert({
        title: 'Warning',
        message: dialogMsgBody.message,
        buttons: [{ label: 'Yes', onClick: () => navigate(-1) }],
        closeOnClickOutside: false
      });
      props.setLoading(false);
      return;
    }

    httpRequest('GET', '/api/audit/rows', {}, props.auth.accessToken, (statusCode, response) => {
      const { status, message, datas } = response;
      props.setLoading(false);
      if (!status) {
        confirmAlert({
          title: 'Warning',
          message: 'Reload data failed',
          buttons: [{ label: 'Yes', onClick: () => navigate(-1)  }],
          closeOnClickOutside: false
        });
        return;
      }
      confirmAlert({
        title: 'Success',
        message: 'Success',
        buttons: [{ label: 'Yes', onClick: () => navigate(-1)  }],
        closeOnClickOutside: false
      });
      props.setAuditDatas(datas);
    });
  };

  if (props.auditSheetDataForm.length === 0 || rowIndex === -1 && !isNew) {
    return null;
  }
  return (
    <div className={viewCss.DealListDetail}>
      <Paper className={viewCss.DealListDetailPaper}>
        {
          props.auditSheetDataForm[AUDIT_ROWS_DATA_FORM_INDEX].layout.classes.map((layoutInfo) => {
            const viewerfieldsInfo = renderFormViewerfieldsInfo(layoutInfo.attr, props.auditSheetDataForm[AUDIT_ROWS_DATA_FORM_INDEX].attributes, formValue);
            return (
              <>
                <div style={{ padding: '10px 0px'}}>
                  <div style={{ padding: '20px 0px'}}>
                    <h2>
                      <FormattedMessage id={layoutInfo.title_id} />
                    </h2>
                  </div>
                  <FormViewer
                    intl={props.intl}
                    onBtnClick={() => {}}
                    onFormChange={(key, newForm) => {
                      setIsFormEdited(true);
                      setFormValue(newForm);
                    }}
                    datas={formValue}
                    fieldsInfo={viewerfieldsInfo}
                  />
                </div>
                <Divider />
              </>
            );
          })
        }
      </Paper>
      <div style={{ padding: '10px 0px', position: 'fixed', bottom: '10px', right: '30px' }}>
        <Button style={{ width: '90px' }} variant="contained" onClick={() => navigate(-1)}>
          <FormattedMessage id='back'/>
        </Button>
        <Button style={{ width: '90px' }} variant="contained" color="primary" onClick={onSubmitForm}>
          <FormattedMessage id={isNew ? 'add' : 'update'}/>
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const { auth, dealList, loading, account, notifyRule, orgMember, auditSheetDataForm, auditDatas, dataInitStatus } = state;
  return { auth, dealList, loading, account, notifyRule, orgMember, auditSheetDataForm, auditDatas, dataInitStatus };
};

export default connect(mapStateToProps, { setAuditDatas, setLoading, setDataInitStatus })(withAuthenticationRequired(injectIntl(AuditRowsForm)));
