const generateQuarterTags = (offset = 0, length = 12) => {
  const quarterList = [];
  const currentDate = new Date();

  let year = currentDate.getFullYear();
  let quarter = Math.floor(currentDate.getMonth() / 3);

  quarter += offset;
  year += Math.floor(quarter / 4);
   // 確保季度在 1 到 4 之間。
   // 第一次取餘數得到-3~3，加上4得到1~7，再取餘數得到0~3，加上1得到1~4
  quarter = (quarter % 4 + 4) % 4 + 1;

  for (let i = 0; i < length; i += 1) {
    quarterList.push(`${year}Q${quarter}`);
    quarter -= 1;
    if (quarter <= 0) {
      year -= 1;
      quarter += 4;
    }
  }
  return quarterList;
};

export {
  generateQuarterTags
};
