import React, { forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, FormattedDate, FormattedMessage } from 'react-intl';
import { Buffer } from "buffer";
// material ui
import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TextField from '@mui/material/TextField';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Tooltip from '@mui/material/Tooltip';
import Grid2 from '@mui/material/Grid2';
import { confirmAlert } from 'react-confirm-alert';

// material ui icons
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ArchiveIcon from '@mui/icons-material/Archive';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import RefreshIcon from '@mui/icons-material/Refresh';
// cust
import { EnhancedTableHead } from '../../../components/TableComponent';
import FileUploader from '../../../components/CustComponents/FileUploader';
import FormDialog from '../../../components/CustComponents/FormDialog';
import { httpRequest, downloadRequest, formatDateStr } from '../../../lib';

import { setLoading } from '../../../actions';
import viewCss from '../../../assets/scss/view.module.scss';

import attachmentTableInfo from '../../../configuration/attachmentTableInfo.json';
import FileViewer from './FileViewer';

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const AttachmentDialog = (props) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('');
  const [fileList, setFileList] = React.useState([]);
  const [uploadFileName, setUploadFileName] = React.useState('');
  const [file, setFile] = React.useState(null);
  const [displayProgress, setDisplayProgress] = React.useState(false);
  // const [pickBoxOpen, setPickBoxOpen] = React.useState(false);
  const [pickRows, setPickRows] = React.useState([]);
  const [pickRowsIndex, setPickRowsIndex] = React.useState([]);
  const [focusRowIndex, setFocusRowIndex] = React.useState(-1);
  const [filePreview, setFilePreview] = React.useState(null);
  const [filePreviewLoading, setFilePreviewLoading] = React.useState(false);

  const refreshFileList = () => {
    const caseId = props.formData ? props.formData.id : '';
    const dataForm = props.formData ? props.formData.dataForm : '';
    const url = `/api/attachment/${dataForm}/${caseId}`;
    props.setLoading(true);
    httpRequest('GET', url, {}, props.auth.accessToken, (statusCode, body) => {
      props.setLoading(false);
      if (body.status) {
        setFileList(body.files);
        props.onLoadFiles(body.files);
      }
    });
  };

  useEffect(() => {
    if (!props.open) {
      setFileList([]);
      setPickRows([]);
      setPickRowsIndex([]);
      setFocusRowIndex(-1);
      if (filePreview) {
        window.URL.revokeObjectURL(filePreview);
        setFilePreview(null);
      }
      return;
    }
    refreshFileList();
  }, [props.open]);

  const handleChangePage = (event, newPage) => {
    setPickRows([]);
    setPickRowsIndex([]);
    setFocusRowIndex(-1);
    if (filePreview) {
      window.URL.revokeObjectURL(filePreview);
      setFilePreview(null);
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const onDownload = (row) => (event) => {
    event.stopPropagation();
    const { id: fileId } = row;
    const dataForm = props.formData ? props.formData.dataForm : '';

    const url = `/api/attachment/download/${dataForm}/${fileId}`;
    props.setLoading(true);
    downloadRequest('GET', url, props.auth.accessToken, (statusCode, body) => {
      props.setLoading(false);
      const blobUrl = window.URL.createObjectURL(body);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = blobUrl;
      a.download = row.name; //filename
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(blobUrl);
      document.body.removeChild(a);
      // alert('your file has downloaded!');

      // if (body.status) {
      //   setFileList(body.files);
      // }
    });
    
  };
  const onPreview = (row) => {
    const { id: fileId } = row;
    const dataForm = props.formData ? props.formData.dataForm : '';

    const url = `/api/attachment/download/${dataForm}/${fileId}`;
    setFilePreviewLoading(true);
    downloadRequest('GET', url, props.auth.accessToken, (statusCode, body) => {
      const blobUrl = window.URL.createObjectURL(body);
      // TODO: remove blobUrl when close
      // window.URL.revokeObjectURL(blobUrl);
      setFilePreviewLoading(false);
      setFilePreview(blobUrl);
    });
  };

  const onFileChange = (file) => {
    if (file) {
      setDisplayProgress(true);
      props.onUploadSubmit(file, () => {
        setDisplayProgress(false);
        refreshFileList();
      });
    }
  };

  const onPickClick = (currentPageRow, index) => (event) => {
    // stop from onChange event
    // event.stopPropagation();
    if (pickRowsIndex.includes(index)) {
      // remmove it
      const pickRIndex = pickRows.findIndex((pr) => {
        return pr.id === currentPageRow.id;
      });
      const pickRIIndex = pickRowsIndex.findIndex((pi) => {
        return pi === index;
      });
      if (pickRIndex > -1) {
        pickRows.splice(pickRIndex, 1);
        setPickRows([...pickRows]);
      } 
      if (pickRIIndex > -1) {
        pickRowsIndex.splice(pickRIIndex, 1);
        setPickRowsIndex([...pickRowsIndex]);
      }
    } else {
      pickRowsIndex.push(index);
      pickRows.push(currentPageRow);
      setPickRowsIndex([...pickRowsIndex]);
      setPickRows(JSON.parse(JSON.stringify(pickRows)));
    }
  };

  const onPickAllClick = (currentPageRows) => (event) => {
    if (pickRows.length === currentPageRows.length) {
      // clear all
      setPickRowsIndex([]);
      setPickRows([]);
    } else {
      // pick all
      const pri = [...Array(currentPageRows.length)].map((v, i) => {
        return i;
      });
      setPickRowsIndex(pri);
      setPickRows(JSON.parse(JSON.stringify(currentPageRows)));
    }
  };

  const mergeDownload = () => {
    // pickRows
    const dataForm = props.formData ? props.formData.dataForm : '';
    const url = `/api/attachment/download/merge_pdf/${dataForm}`;
    props.setLoading(true);
    const formatORT = props.intl.formatMessage({ id: props.formData.original_receipt_type });
    const formatORN = props.formData.original_receipt_number;
    const formatD = formatDateStr(new Date(), '{{yyyy}}{{MM}}{{dd}}');
    const fileName = `${formatORT}_${formatORN}_${formatD}.pdf`;

    httpRequest('POST', url, {
      files: pickRows
    }, props.auth.accessToken, (statusCode, body) => {
      props.setLoading(false);
      if (body.status) {
        // todo edit filename
        const myBuffer = Buffer.from(body.data);
        const myBlob = new Blob([myBuffer]);
        const blobUrl = window.URL.createObjectURL(myBlob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = blobUrl;
        a.download = fileName; //filename
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(blobUrl);
      } else {
        confirmAlert({
          title: props.intl.formatMessage({ id: 'warning' }),
          message: body.message,
          buttons: [{ label: props.intl.formatMessage({ id: 'confirm' }) }],
          closeOnClickOutside: false
        });
      }
    });
  };

  // const displayRows = props.attachmentInfos;
  const displayRows = stableSort(fileList, getComparator(order, orderBy))
  if (props.loading) {
    return;
  }
  const displayPreview = (focusRowIndex > -1 && filePreview);
  const tableGridSm = displayPreview ? 6 : 12;
  return (
    <FormDialog
      open={props.open}
      handleDelete={props.handleDelete}
      handleClose={props.handleClose}
      handleSave={props.handleSave}
      children={props.children}
      deleteText={props.deleteText}
      closeText={props.closeText}
      saveText={props.saveText}
      maxWidth={displayPreview ? 'xl': 'md' }
      PaperProps={displayPreview ? { style: { height: '90%' } } : {}}
    >
      <div style={{ height: '60px' }}>
        <h2 style={{ float: 'left' }}>
        {
          (pickRowsIndex.length > 0) ?
          <FormattedMessage id='att_select_count' values={{ count: pickRowsIndex.length }}/>
          :
          props.formData && props.formData.original_receipt_number ? (
            <>
              <FormattedMessage id={props.formData.original_receipt_type}/>&nbsp;
              {props.formData.original_receipt_number}
            </>
          ) : (
            <FormattedMessage id='attachment'/>
          )
        }
        </h2>
        <FileUploader
          intl={props.intl}
          btnStyle='icon'
          style={{ float: 'right' }}
          fileName={uploadFileName}
          placeholder={props.intl.formatMessage({ id: 'upload_file' })}
          onFileChange={onFileChange}
        />
        <Tooltip title={props.intl.formatMessage({ id: 'refresh' })}>
          <IconButton
            aria-label='view'
            size='large'
            style={{ float: 'right' }}
            onClick={() => {
              refreshFileList();
            }}
          >
            <RefreshIcon color='primary'/>
          </IconButton>
        </Tooltip>
        <Tooltip title={props.intl.formatMessage({ id: 'merge_download' })}>
          <IconButton
            aria-label='view'
            size='large'
            style={{ float: 'right' }}
            onClick={mergeDownload}
            disabled={pickRowsIndex.length === 0}
          >
            <ArchiveIcon color={(pickRowsIndex.length === 0) ? '' : 'primary'}/>
          </IconButton>
        </Tooltip>
      </div>
      {displayProgress && <LinearProgress style={{ width: '100%' }}/>}
      
      <Grid2 container spacing={2} style={{ height: 'calc(100% - 50px)'}}>
        <Grid2 item size={{ xs: 12, sm: tableGridSm }}>
          <Paper className={viewCss.DealListPaper}>
            <TableContainer style={{ padding: '25px 0px 0px 0px' }}>
              <Table
                className={viewCss.DealListTable}
                aria-labelledby="tableTitle"
                size={'small'}
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  onRequestSort={handleRequestSort}
                  heads={attachmentTableInfo}
                  order={order}
                  orderBy={orderBy}
                  pickBox={true}
                  pickIndet={pickRows.length > 0 && pickRows.length < displayRows.length}
                  pickAllChecked={pickRows.length === displayRows.length}
                  onPickAllClick={onPickAllClick(displayRows)}
                />
                <TableBody>
                  {
                    displayRows.map((row, index) => {
                      const rowClassName = (focusRowIndex === index) ? viewCss.DealListCalmRowSelected : viewCss.DealListCalmRow;
                      return (
                        <TableRow
                          hover
                          role='checkbox'
                          tabIndex={-1}
                          key={`${index}`}
                          className={rowClassName}
                          onClick={(event) => {
                            if (focusRowIndex === index) {
                              window.URL.revokeObjectURL(filePreview);
                              setFocusRowIndex(-1);
                              setFilePreview(null);
                              return;
                            }
                            setFocusRowIndex(index);
                            onPreview(row);
                          }}
                        >
                          <TableCell padding="checkbox" key={`table_cell_pick`}>
                            <Checkbox
                              checked={pickRowsIndex.includes(index)}
                              onClick={(event) => event.stopPropagation()}
                              onChange={onPickClick(row, index)}
                            />
                          </TableCell>
                          {
                            attachmentTableInfo.map((headCell, headCellIndex) => {
                              if (headCell.type === 'download') {
                                return (
                                  <TableCell padding="checkbox" key={`table_cell_${index}_${headCellIndex}`}>
                                    <Tooltip title={props.intl.formatMessage({ id: 'download' })}>
                                      <IconButton aria-label="view" onClick={onDownload(row)}>
                                        <CloudDownloadIcon color='primary'/>
                                      </IconButton>
                                    </Tooltip>
                                  </TableCell>
                                );
                              }
                              if (headCell.type === 'boolean') {
                                return row[headCell.id] ? (
                                  <TableCell align="left" key={`table_cell_${index}_${headCellIndex}`}>
                                    <CheckCircleIcon style={{ fontSize: '16px' }}/>
                                  </TableCell>
                                ) : (
                                  <TableCell align="left" key={`table_cell_${index}_${headCellIndex}`}/>
                                );
                              }
                              if (headCell.type === 'date') {
                                return (
                                  <TableCell align="left" key={`table_cell_${index}_${headCellIndex}`}>
                                    {
                                      row[headCell.id] && <FormattedDate value={row[headCell.id]} />
                                    }
                                  </TableCell>
                                );
                              }
                              return (
                                <TableCell align="left" padding="none" key={`table_cell_${index}_${headCellIndex}`}>
                                  {row[headCell.id]}
                                </TableCell>
                              );
                            })
                          }
                        </TableRow>
                      );
                    })
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={displayRows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid2>
        {
          filePreview && (
            <Grid2 item size={{ xs: 12, sm: 6 }}>
              <FileViewer
                title={focusRowIndex > -1 ? displayRows[focusRowIndex].name : ''}
                displayLoading={filePreviewLoading}
                fileType={focusRowIndex > -1 ? displayRows[focusRowIndex].fileExtension : ''}
                src={filePreview}
              />
            </Grid2>
          )
        }
      </Grid2>
    </FormDialog>
  );
};

AttachmentDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  saveText: PropTypes.string.isRequired,
  closeText: PropTypes.string,
  deleteText: PropTypes.string,
  handleSave: PropTypes.func.isRequired,
  handleClose: PropTypes.func,
  handleDelete: PropTypes.func,
  formData: PropTypes.object.isRequired,
  onUploadSubmit: PropTypes.func.isRequired,
};


const mapStateToProps = (state) => {
  const { auth, loading } = state;
  return {
    auth, loading
  };
};

export default connect(mapStateToProps, {
  setLoading
})(injectIntl(AttachmentDialog));
