import React from 'react';
import { Grid2, Typography } from '@mui/material';

import viewCss from '../../assets/scss/view.module.scss';

const NotFound = () => {

  return (
    <div className={viewCss.ViewNotFund}>
      <Grid2 container justify="center" spacing={4}>
        <Grid2 item size={{ lg: 6, xs: 12 }}>
          <div className={viewCss.ViewNotFundContent}>
            <Typography variant="h1">
              404: The page you are looking for isn’t here
            </Typography>
            <Typography variant="subtitle2">
              You either tried some shady route or you came here by mistake.
              Whichever it is, try using the navigation
            </Typography>
            <img
              alt="Under development"
              className={viewCss.ViewNotFundImage}
              src="/images/undraw_page_not_found_su7k.svg"
            />
          </div>
        </Grid2>
      </Grid2>
    </div>
  );
};

export default NotFound;
